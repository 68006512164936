import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ElectionContext } from "./election.context";
import { ErrorPanel } from "../../components/error-panel";
import { BallotOptInPreference, IBallotResponse } from "../../domain/ballot";
import { RESPONSE_TYPE } from "../../const/vote";
import { sortResult } from "./util";
import { Loading } from "../../components/loading";
import { SortResult } from "../../components/rank-sort/types";
import { AxiosError, AxiosResponse } from "axios";
import { HttpErrorPanel } from "../../components/http-error";
import { HeavyCard } from "../../components/heavy-card";
import { AuthContext } from "../../context/auth.provider";
import { CONSUMER_TYPE } from "../../domain/consumer";
import { ButtonPanel } from "../../components/button-panel";
import { useLabel } from "../../context/label-cache.provider";
import { IBallotPage } from "../../domain/ballot-page";
import { useFetchState } from "../../context/api.provider";
import {
  BallotPageContext,
  BallotPageProvider,
} from "./ballot-page/ballot-page.context";
import { BallotContext, BallotProvider } from "./ballot/ballot.context";
export const ReviewPage = () => {
  const { consumer } = useContext(AuthContext);
  const { ballotPages, submit, election } = useContext(ElectionContext);

  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState<AxiosResponse | AxiosError | null>(null);
  const done = useMemo(() => !!result && result.status === 200, [result]);
  const allowedToSubmit = useMemo(() => {
    return consumer && consumer.type === CONSUMER_TYPE.VOTER;
  }, [consumer]);
  if (!consumer) {
    return <ErrorPanel text="Must have consumer" source="ReviewPage" />;
  }
  if (!election) {
    return <ErrorPanel text="Must have ballots" source="ReviewPage" />;
  }

  const handleSubmit = () => {
    setSubmitting(true);
    setTimeout(() => submit().then((r) => setResult(r)), 200);
  };

  const handleBack = () => {
    setResult(null);
    setSubmitting(false);
  };

  if (result && result.status !== 200 && "response" in result) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <HttpErrorPanel
            text="Submit failed"
            source="ReviewPage"
            axiosResponse={result.response}
          />
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (submitting || done) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Review</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Thank you for participating in this election.</Typography>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Loading source="Review" waitingFor={{ Submit: done }} />
          {done ? (
            <Typography
              color="green"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              Submitted!
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Review</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Please review your submission before submitting. After submission you
          will not be able to change your vote.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {ballotPages.map((b) => (
            <Grid item xs={12} key={b.id}>
              <BallotPageProvider ballotPage={b}>
                <BallotPageReview />
              </BallotPageProvider>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ButtonPanel
          color={allowedToSubmit ? undefined : "warning"}
          title={
            allowedToSubmit ? undefined : "Cannot submit votes in preview mode."
          }
          back={{ to: `/election/${election.election_id}` }}
          next={{
            label: "Submit",
            icon: "submit",
            onClick: handleSubmit,
            disabled: !allowedToSubmit,
          }}
        />
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

const BallotPageReview = () => {
  const { ballotPage, ballots, ballotResponses } =
    useContext(BallotPageContext);

  return (
    <HeavyCard title={ballotPage.label}>
      {ballots.map((b) => (
        <BallotProvider ballot={b} key={b.id}>
          <BallotReview />
        </BallotProvider>
      ))}
    </HeavyCard>
  );
};

const BallotReview = () => {
  const { ballot, ballotResponse } = useContext(BallotContext);
  return (
    <Box title={ballot.label}>
      <Grid container>
        <Grid item>
          {ballotResponse && ballotResponse.vote ? (
            <ResponseReview
              key={ballotResponse.ballot_id}
              response={ballotResponse}
            />
          ) : (
            <Typography color="error">ERROR EMPTY RESPONSE</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface ResponseOptInReviewProps {
  require_opt_in: BallotOptInPreference;
  opt_in_details: any;
}
const ResponseOptInReview = ({
  require_opt_in,
  opt_in_details,
}: ResponseOptInReviewProps) => {
  return (
    <Box>
      <Box>{require_opt_in.title}</Box>
      <Box>{JSON.stringify(opt_in_details)}</Box>
    </Box>
  );
};

interface ResponseReviewRankingProps {
  response: IBallotResponse;
}
const ResponseReview = ({ response }: ResponseReviewRankingProps) => {
  const { type, vote } = response;
  switch (type) {
    case RESPONSE_TYPE.YES_NO:
      return (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell align="center">Response</TableCell>
              <TableCell>{vote}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    case RESPONSE_TYPE.PREFERENCE:
      const ranking = Object.values(vote).filter((v) => v.index !== null);
      ranking.sort(sortResult);
      return (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={80}>
                Preference
              </TableCell>
              <TableCell>Candidate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ranking.map((r, i) => (
              <TableRow key={i}>
                <TableCell align="center">{r.index}</TableCell>
                <TableCell>
                  <CandidateName candidate_id={r.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
  }
  return null;
};

interface CandidateNameProps {
  candidate_id: string;
}
const CandidateName = ({ candidate_id }: CandidateNameProps) => {
  const label = useLabel("candidate", candidate_id);

  return <Typography>{label}</Typography>;
};
