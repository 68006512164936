import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useFetchState } from "../context/api.provider";
import { HeavyCard } from "../components/heavy-card";
import { staticFetch } from "../util/api";

export const LandingPage = () => {
  const [email, setEmail] = useState<string>("");
  const [sendEmailResponse, setSendEmailResponse] = useState<any>();
  const handleSendEmail = () => {
    staticFetch({
      url: "/attempt-invite",
      data: { email },
      method: "post",
    }).then((res) => setSendEmailResponse(res.data));
  };
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h1">Hello!</Typography>
        <Typography variant="h4">You have arrived here by mistake.</Typography>
      </Grid>
      <Grid item xs={12}>
        {sendEmailResponse ? (
          <HeavyCard title="Submitted">
            <Typography>
              If you're registered for any elections you will receive an email
              with an invite link.
            </Typography>
          </HeavyCard>
        ) : (
          <HeavyCard title="Expecting to see an election here?">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>
                  Enter your registered email address here and if you're
                  registered for an election we will resend the invite link.
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  label="Registered Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  onClick={handleSendEmail}
                  variant="contained"
                  disabled={!email || !email.includes("@")}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </HeavyCard>
        )}
      </Grid>
    </Grid>
  );
};
