import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { Loading } from "../../components/loading";
import { IBallot } from "../../domain/ballot";
import { ElectionContext, INTERFACE_STATE } from "./election.context";
import { AuthContext } from "../../context/auth.provider";
import { formateDate, sortResult } from "./util";
import { VOTER_STATUS } from "../../domain/voter";
import { ApiBallotPage, ELECTION_STATUS } from "../../domain/election";
import React from "react";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import { CONSUMER_TYPE } from "../../domain/consumer";
import { LinkBtn } from "../../components/lnk-btn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import BallotIcon from "@mui/icons-material/Ballot";

export const ElectionOverviewPage = () => {
  const { consumer } = useContext(AuthContext);
  const { election } = useContext(ElectionContext);

  const pages = useMemo(() => Object.values(election.ballot_pages), [election]);

  const status =
    consumer && consumer.type === CONSUMER_TYPE.VOTER
      ? consumer.voter.status
      : VOTER_STATUS.VIEWED;

  const initialOpen = useMemo(() => {
    for (const page of pages) {
      if (!page.submitted) return page.id;
    }
    if (pages.length > 0) {
      return pages[0].id;
    }
  }, [pages]);

  if (!consumer || !election || !pages || !initialOpen) {
    return <Loading source="ElectionPanel" waitingFor={{ election, pages }} />;
  }

  const { voting_open_at, voting_close_at } = election;

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3">{election.label}</Typography>
          <Typography variant="h5">{election.status}</Typography>
          {voting_open_at ? (
            <Typography variant="h5">
              Opens: {formateDate(voting_open_at)}
            </Typography>
          ) : null}
          {voting_close_at ? (
            <Typography variant="h5">
              Closes: {formateDate(voting_close_at)}
            </Typography>
          ) : null}
          <hr />
        </Grid>

        <Grid item xs={12} mt={4}>
          <Typography>You're enrolled to vote:</Typography>

          {pages.map((b, i) => (
            <BallotPageCard
              key={b.id}
              ballotPage={b}
              initialOpen={initialOpen === b.id}
            />
          ))}
        </Grid>
        <Grid item xs={12} mt={4}>
          <ActionPanel />
        </Grid>
      </Grid>
    </Container>
  );
};

interface BallotPageCardProps {
  ballotPage: ApiBallotPage;
  initialOpen: boolean;
}
export const BallotPageCard = ({
  ballotPage,
  initialOpen,
}: BallotPageCardProps) => {
  const ballots = useMemo(
    () => Object.values(ballotPage.ballots),
    [ballotPage]
  );
  return (
    <Accordion
      defaultExpanded={initialOpen}
      sx={{
        border: "solid 1px",
        borderColor: "#AAA",
        boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HowToVoteIcon color="primary" sx={{ marginRight: 2 }} />
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Typography>{ballotPage.label}</Typography>
          {ballotPage.submitted ? (
            <Chip
              variant="outlined"
              color="success"
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>Submitted</span>
                  <CheckIcon sx={{ marginLeft: 1 }} />
                </Box>
              }
            />
          ) : null}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box paddingLeft={2}>
          <Typography paddingLeft={2}>Ballots</Typography>

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              boxSizing: "border-box",
            }}
          >
            {ballots.map((b, i) => (
              <BallotCard key={b.id} ballot={b} index={i} />
            ))}
          </List>
        </Box>

        <Box display="flex" justifyContent="center">
          {!ballotPage.submitted ? (
            <LinkBtn
              to={`/election/${ballotPage.election_id}/ballot_page/${ballotPage.id}/vote`}
              variant="contained"
              fullWidth
            >
              Begin
            </LinkBtn>
          ) : (
            <Chip
              variant="outlined"
              color="success"
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>Submitted</span>
                  <CheckIcon sx={{ marginLeft: 1 }} />
                </Box>
              }
            />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

interface BallotCardProps {
  index: number;
  ballot: IBallot;
}
const BallotCard = ({ ballot, index }: BallotCardProps) => {
  return (
    <>
      {index > 0 ? <Divider variant="inset" component="li" /> : null}
      <ListItem alignItems="flex-start" sx={{ width: "100%" }}>
        <ListItemAvatar>
          <BallotIcon color="primary" />
        </ListItemAvatar>
        <ListItemText
          primary={ballot.label}
          secondary={ballot.short_description}
        />
      </ListItem>
    </>
  );
};

const ActionPanel = () => {
  const { interfaceState, election } = useContext(ElectionContext);

  switch (interfaceState) {
    case INTERFACE_STATE.ELECTION_ALREADY_CLOSED:
      return (
        <Typography>
          This election has is closed or cancelled. Thank you for your interest.
        </Typography>
      );

    case INTERFACE_STATE.ELECTION_NOT_YET_OPEN:
      return (
        <Typography>
          This election is not yet open for voting. Thank you for your interest.
        </Typography>
      );

    case INTERFACE_STATE.ALREADY_SUBMITTED:
      return (
        <Card variant="outlined" sx={{ marginTop: 10 }}>
          <Box p={3} sx={{ background: "#fbf7e9" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  You have already submitted responses to all eligible ballots
                  in this election.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Link to={`/election/${election.election_id}/dispute`}>
                  <Button fullWidth>
                    Haven't voted yet?
                    <br />
                    Click here if you believe this is an error.
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Card>
      );

    case INTERFACE_STATE.LOADING:
      return <span>loading...</span>;
  }
  return null;
};
