import axios, { AxiosRequestConfig } from "axios";
import { API_BASE } from "../const/config";
import jwt from "jwt-encode";

export const staticAuthFetch = (
    config: AxiosRequestConfig<any>,
    publicKey?: string,
    secretKey?: string
) => {
    if (!config.headers) {
        config.headers = {};
    }
    config.headers["Accept"] = "application/json";
    config.url = "/auth" + config.url;

    if (publicKey && secretKey) {
        var token = jwt({ public_key: publicKey }, secretKey);
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return staticFetch(config);
};


export const staticFetch = (
    config: AxiosRequestConfig<any>,
) => {
    if (!config.headers) {
        config.headers = {};
    }
    config.headers["Accept"] = "application/json";
    if (!config.url || !config.url.startsWith('http')) {
        config.url = API_BASE + config.url;
    }
    return axios(config);
};