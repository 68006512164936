import { Card, Box, Grid, Typography } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo } from "react";
import { HeavyCard } from "./heavy-card";

interface HttpErrorProps {
  text?: string;
  source?: string;
  axiosResponse?: AxiosResponse<any>;
  meta?: any;
}
export const HttpErrorPanel = ({
  text,
  source,
  axiosResponse,
  meta,
}: HttpErrorProps) => {
  const headline = useMemo(() => {
    if (axiosResponse) {
      const { statusText, data } = axiosResponse;
      if (data && data.code) return data.code;
      return statusText;
    }
    return "Unknown";
  }, [axiosResponse]);
  const httpLines = useMemo(() => {
    if (!axiosResponse) {
      return [];
    }
    const e = [];
    if (axiosResponse) {
      const { statusText, data } = axiosResponse;
      if (statusText) e.push(statusText);
      if (data && data.code) e.push(data.code);
      if (data && data.type) e.push(data.type);
      if (data && data.pattern) e.push(data.pattern);
    }
    return e;
  }, [axiosResponse]);
  return (
    <Box sx={{ width: "100%" }}>
      <HeavyCard>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: 20,
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {text}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    background: "#FfdFdf",
                    width: "100%",
                    padding: 1,
                    border: "1px solid #D44",
                  }}
                  variant="h6"
                >
                  {headline}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">[{source}]</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ background: "#EEE", padding: 1 }}>
                {httpLines.map((e, i) => (
                  <Typography key={i} variant="body1" color="error">
                    {e}
                  </Typography>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {meta ? JSON.stringify(meta, null, 2) : null}
            </Grid>
          </Grid>
        </Box>
      </HeavyCard>
    </Box>
  );
};
