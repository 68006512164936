import React, {
  createContext,
  useState,
  PropsWithChildren,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { LocalStorageContext } from "./localstorage.provider";
import { staticAuthFetch } from "../util/api";
import { Loading } from "../components/loading";
import { Grid, Typography } from "@mui/material";
import { HttpErrorPanel } from "../components/http-error";
export enum AUTH_STATUS {
  VERIFYING = "VERIFYING",
  AUTHENTICATED = "AUTHENTICATED",
  UNAUTHENTICATED = "UNAUTHENTICATED",
}
export interface IApiContext {
  fetch: <T = any>(
    config: AxiosRequestConfig<T>
  ) => Promise<AxiosResponse<any>>;
}

export interface HttpRequest {}

export const ApiContext = createContext<IApiContext>({
  fetch: () => {
    throw new Error("Not initialized");
  },
});

export const ApiProvider: React.FC<PropsWithChildren> = (props) => {
  const { getString } = useContext(LocalStorageContext);
  const publicKey = getString("public_key");
  const secretKey = getString("secret_key");

  const fetch = useMemo(() => {
    return (config: AxiosRequestConfig<any>) => {
      return staticAuthFetch(config, publicKey, secretKey);
    };
  }, [publicKey, secretKey]);

  return (
    <ApiContext.Provider value={{ fetch }}>
      {props.children}
    </ApiContext.Provider>
  );
};

type FetchState<T = any> = [AxiosResponse<T> | null, FetchHandler<T>, boolean];
type ProcessData<R = any, T = any> = (data: R) => T;
type FetchHandler<T = any> = (
  config: AxiosRequestConfig | (() => Promise<AxiosResponse<T>>),
  processData?: ProcessData<any, T>
) => Promise<any>;

export function useFetchState<T = any>(): FetchState<T> {
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  const { fetch } = useContext(ApiContext);

  const singleFetch: FetchHandler = (
    config: AxiosRequestConfig<any> | (() => Promise<any>),
    processData?: ProcessData<any, T>
  ): Promise<any> => {
    setFetching(true);
    const processResponse = (res: AxiosResponse<any>): AxiosResponse<T> => {
      if (processData) {
        return {
          ...res,
          data: processData(res.data),
        };
      }
      return res;
    };
    if (config instanceof Function) {
      return config()
        .then((r) => {
          setFetching(false);
          setResponse(processResponse(r));
        })
        .catch((e) => {
          setFetching(false);
          setResponse(e);
        });
    } else {
      return fetch(config)
        .then((r) => {
          setFetching(false);
          setResponse(processResponse(r));
        })
        .catch((e) => {
          setFetching(false);
          setResponse(e);
        });
    }
  };

  return [response, singleFetch, fetching];
}
