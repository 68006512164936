import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useContext,
  useCallback,
} from "react";
import { AUTH_STATUS, AuthContext } from "./auth.provider";
import { ApiContext } from "./api.provider";

type LabelCache = { [key: string]: string };

export interface ILabelCacheContext {
  fetchLabel: (type: string, id: string) => Promise<string>;
}

export const LabelCacheContext = createContext<ILabelCacheContext>({
  fetchLabel: (type: string, id: string) =>
    Promise.resolve("not initialised 2859"),
});

export const LabelCacheProvider: React.FC<PropsWithChildren> = (props) => {
  const [labelCache, setLabelCache] = useState<LabelCache>({});

  const { status } = useContext(AuthContext);
  const { fetch } = useContext(ApiContext);

  const _fetchLabel = useCallback(
    async (type: string, id: string): Promise<string> => {
      switch (type) {
        case "election":
          return fetch({ url: `/elections/${id}` }).then(
            (res) => res.data.label
          );
        case "ballot_page":
          return fetch({ url: `/ballot_pages/${id}` }).then(
            (res) => res.data.label
          );
        case "ballot":
          return fetch({ url: `/ballots/${id}` }).then((res) => res.data.label);
        case "candidate":
          return fetch({ url: `/candidates/${id}` }).then(
            (res) => res.data.label
          );
      }
      throw new Error(`Unkknown type: ${type}`);
    },
    [fetch]
  );

  const fetchLabel = useCallback(
    async (type: string, id: string): Promise<string> => {
      const key = `${type}:${id}`;
      if (labelCache[key] !== undefined) {
        return labelCache[key];
      }
      const label = await _fetchLabel(type, id);
      setLabelCache((lc) => ({ ...lc, [key]: label }));
      return label;
    },
    [labelCache, _fetchLabel]
  );

  return (
    <LabelCacheContext.Provider value={{ fetchLabel }}>
      {props.children}
    </LabelCacheContext.Provider>
  );
};

export const useLabel = (type: string, id: string) => {
  const [label, setLabel] = useState<string>("..");
  const { fetchLabel } = useContext(LabelCacheContext);
  useEffect(() => {
    fetchLabel(type, id).then(setLabel);
  }, [type, id]);
  return label;
};
