import React, { useContext } from "react";
import { Loading } from "../components/loading";
import { AuthContext } from "../context/auth.provider";
import { Redirect } from "../components/redirect";

export const InitializePage = () => {
  const { consumer } = useContext(AuthContext);

  if (!consumer) {
    return (
      <Loading source="InitializePage" waitingFor={{ Voter: !!consumer }} />
    );
  }

  return <Redirect to={`/election/${consumer.election_id}`} />;
};
