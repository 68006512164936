import { Grid, Typography } from "@mui/material";
import { Loading } from "../components/loading";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/auth.provider";
import { useNavigate, useLocation } from "react-router-dom";
import { IConsumerApiToken, ILoginToken } from "../domain/email-token";

export const LoginTokenPage = () => {
  const { hash } = useLocation();

  const { authByLoginToken } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(hash, hash.length);
  useEffect(() => {
    if (hash) {
      authByLoginToken(hash.replace("#", "")).then(
        (consumerApiToken: IConsumerApiToken) => {
          if (consumerApiToken.election_id) {
            navigate("/election/" + consumerApiToken.election_id);
          } else {
            navigate("/");
          }
        }
      );
    }
  }, [hash]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h2">Authenticating...</Typography>
      </Grid>
    </Grid>
  );
};
