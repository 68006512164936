import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTimeout } from "../hooks";
import "./loading.scss";
interface CheckProps {
  message?: string;
  done?: boolean;
}
export const Check = ({ message, done }: CheckProps) => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    if (done) setTimeout(() => setAnimate(true), 1000);
  });
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div
            className={
              animate ? "circle-loader load-complete" : "circle-loader"
            }
          >
            <div
              className="checkmark draw"
              style={{ display: animate ? undefined : "none" }}
            ></div>
          </div>
        </Box>
        {done && message ? (
          <Typography
            color="#5cb85c"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {message}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};
