import { Box, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { ErrorPanel } from "../../components/error-panel";
import {
  ElectionContext,
  ElectionProvider,
  IElectionStep,
  INTERFACE_STATE,
} from "./election.context";
import { ApiElectionRound } from "../../domain/election";
import { useFetchState } from "../../context/api.provider";
import { Loading } from "../../components/loading";
import { AuthContext } from "../../context/auth.provider";

export const ElectionLayout = () => {
  const { consumer } = useContext(AuthContext);
  const { election_id } = useParams();

  if (!election_id || !consumer) {
    return <ErrorPanel text="Must have election_id" source="ElectionPage" />;
  }

  return (
    <ElectionProvider
      election_id={election_id}
      consumer_id={consumer.id || "guest"}
    >
      <ElectionLayoutInner />
    </ElectionProvider>
  );
};
export const ElectionLayoutInner = () => {
  const { election } = useContext(ElectionContext);
  const { consumer } = useContext(AuthContext);

  if (!election) {
    return <ErrorPanel text="Must have election" source="ElectionPage" />;
  }
  if (!consumer) {
    return <ErrorPanel text="Must have consumer" source="ElectionPage" />;
  }

  return (
    <Box>
      <Outlet />
    </Box>
  );
};

// const Steps = () => {
//   const { steps, currentStep } = useContext(ElectionContext);
//   const [pad, setPad] = useState<number>(0);
//   const [width, setWidth] = useState(1);

//   const handleRef = (e: HTMLDivElement) => {
//     if (e) {
//       setPad(e.getBoundingClientRect().height);
//       setWidth(e.getBoundingClientRect().width);
//     }
//   };
//   return (
//     <Box sx={{ paddingBottom: `${pad}px` }}>
//       <Box
//         ref={handleRef}
//         sx={{
//           display: "flex",
//           justifyContent: "space-around",
//           position: "fixed",
//           bottom: 0,
//           left: 0,
//           right: 0,
//           borderTop: "1px solid #AAA",
//           padding: 2,
//           boxSizing: "border-box",
//           background: "#FFF",
//         }}
//       >
//         {steps.map((s, i) => (
//           <Step
//             key={i}
//             step={s}
//             index={i}
//             selected={currentStep === i}
//             hideLabel={width < 800}
//           />
//         ))}
//       </Box>
//     </Box>
//   );
// };

const SELECT_COLOR = "#556cd6";
interface StepProps {
  index: number;
  step: IElectionStep;
  selected: boolean;
  hideLabel: boolean;
}
const Step = ({ step, index, selected, hideLabel }: StepProps) => {
  const { icon, url, label } = step;
  return (
    <Box>
      <Link
        to={step.url}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textDecoration: "none",
          color: selected ? SELECT_COLOR : "#444",
        }}
      >
        <Box
          sx={{
            border: selected ? "2px solid " + SELECT_COLOR : undefined,
            height: 40,
            width: 40,
            borderRadius: 30,
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textDecoration: "none",
          }}
        >
          {icon ? icon : index + 1}
        </Box>
        {hideLabel ? null : (
          <Typography>
            {index + 1}. {label}
          </Typography>
        )}
      </Link>
    </Box>
  );
};
