import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { createContext } from "react";
import {
  BallotOptInResponse,
  IBallotResponse,
  IBallot,
  IBallotPageResponse,
} from "../../../domain/ballot";

import { useFetchState } from "../../../context/api.provider";
import { ElectionContext } from "../election.context";
import { Loading } from "../../../components/loading";
import { IBallotOptIn } from "../../../domain/ballot-opt-in";
import { ApiBallotPage, ApiIBallot } from "../../../domain/election";
type BallotRespponses = { [ballot_id: string]: IBallotResponse };
export enum INTERFACE_STATE {
  LOADING = "LOADING",
  VOTING_ALLOWED = "VOTING_ALLOWED",
  ALREADY_SUBMITTED = "ALREADY_SUBMITTED",
  ELECTION_NOT_YET_OPEN = "ELECTION_NOT_YET_OPEN",
  ELECTION_ALREADY_CLOSED = "ELECTION_ALREADY_CLOSED",
}

export interface SubmitResult {
  success: boolean;
  message?: string;
  code: string;
}

export interface IBallotPageContext {
  ballotPage: ApiBallotPage;
  ballotResponses: BallotRespponses;
  setBallotResponse: (
    ballot_id: string,
    value: React.SetStateAction<IBallotResponse>
  ) => void;
  setOptIn: (optInResponse: BallotOptInResponse) => void;
  optInResponse?: BallotOptInResponse;
  ballots: Array<ApiIBallot>;
  ballotOptIn: IBallotOptIn | null;
  ballotPageResponse: IBallotPageResponse;
}

export const BallotPageContext = createContext<IBallotPageContext>({
  ballotPage: {} as any,
  ballotResponses: {},
  setBallotResponse: (
    ballot_id: string,
    value: React.SetStateAction<IBallotResponse>
  ) => null,
  setOptIn: (optInResponse: BallotOptInResponse) => null,
  ballots: [],
  ballotOptIn: null,
  ballotPageResponse: {} as any,
});
interface BallotPageProviderProps extends PropsWithChildren {
  ballotPage: ApiBallotPage;
}
export const BallotPageProvider = ({
  children,
  ballotPage,
}: BallotPageProviderProps) => {
  const { setBallotPageResponse, pageResponses } = useContext(ElectionContext);
  const pageResponse: IBallotPageResponse | undefined =
    pageResponses[ballotPage.id];
  const ballots = useMemo(
    () => Object.values(ballotPage.ballots),
    [ballotPage]
  );
  useEffect(() => {
    if (!pageResponse) {
      console.log("initialise pageResponse");
      setBallotPageResponse(ballotPage.id, {
        ballot_page_id: ballotPage.id,
        votes: {},
      });
    }
  }, [pageResponse, setBallotPageResponse]);

  const resolveBallot = useCallback(
    (ballot: string | IBallot) => {
      if (typeof ballot === "object") {
        return ballot;
      }
      if (typeof ballot === "string" && ballots) {
        return ballots.find((b) => b.id === ballot);
      }
    },
    [ballots]
  );

  const setBallotResponse = useCallback(
    (ballotId: string, value: React.SetStateAction<IBallotResponse>) => {
      const _ballot = resolveBallot(ballotId);
      if (!_ballot) {
        throw new Error("Must have ballot");
      }
      setBallotPageResponse(ballotPage.id, (pageResponse) => {
        const current = pageResponse.votes[ballotId] || {
          ballot_id: ballotId,
          type: _ballot.response_type,
        };

        const response: IBallotResponse =
          typeof value === "function" ? value(current) : value;

        return {
          ...pageResponse,
          votes: {
            ...pageResponse.votes,
            [ballotId]: response,
          },
        };
      });
    },
    [setBallotPageResponse, resolveBallot]
  );
  const handleSetOptIn = (value: BallotOptInResponse) => {
    setBallotPageResponse(ballotPage.id, (pageResponse) => {
      return {
        ...pageResponse,
        optIn: value,
      };
    });
  };

  if (!ballots) {
    return <Loading source="BallotPageProvider" />;
  }

  if (!pageResponse) {
    return <Loading source="pageResponse" />;
  }

  return (
    <BallotPageContext.Provider
      value={{
        ballotPage,
        ballots: ballots,
        ballotResponses: pageResponse.votes,
        optInResponse: pageResponse.optIn,
        setBallotResponse,
        setOptIn: handleSetOptIn,
        ballotOptIn: null,
        ballotPageResponse: pageResponse,
      }}
    >
      {children}
    </BallotPageContext.Provider>
  );
};
