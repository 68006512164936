import { Grid, Button, Box } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { IBallot, REFEREDUM_VALUE } from "../../../../domain/ballot";
import CheckIcon from "@mui/icons-material/Check";
import { BallotContext } from "../ballot.context";
import { RESPONSE_TYPE } from "../../../../const/vote";

export const ResponseYesNo = () => {
  const { ballotResponse, setBallotVote, setCandidateRank } =
    useContext(BallotContext);
  const vote =
    ballotResponse && ballotResponse.type === RESPONSE_TYPE.YES_NO
      ? ballotResponse.vote
      : null;

  const handleYes = () => {
    setBallotVote({
      type: RESPONSE_TYPE.YES_NO,
      vote: REFEREDUM_VALUE.YES,
    });
  };
  const handleNo = () => {
    setBallotVote({
      type: RESPONSE_TYPE.YES_NO,
      vote: REFEREDUM_VALUE.NO,
    });
  };
  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12} p={1}>
        <LargeCheckButton
          label="YES"
          checked={vote === REFEREDUM_VALUE.YES}
          onClick={handleYes}
        />
      </Grid>
      <Grid item sm={6} xs={12} p={1}>
        <LargeCheckButton
          label="NO"
          checked={vote === REFEREDUM_VALUE.NO}
          onClick={handleNo}
        />
      </Grid>
    </Grid>
  );
};
interface LargeCheckButtonProps {
  onClick: () => void;
  label: string;
  checked: boolean;
}
const LargeCheckButton = ({
  onClick,
  label,
  checked,
}: LargeCheckButtonProps) => {
  return (
    <Button
      variant={"outlined"}
      onClick={onClick}
      fullWidth
      sx={{ padding: 2, fontSize: 25 }}
    >
      <Box
        sx={{
          border: "#777" + " solid  2px ",
          height: 50,
          width: 50,
          marginRight: 2,
          borderRadius: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textDecoration: "none",
        }}
      >
        {checked ? (
          <CheckIcon sx={{ fontSize: 50, color: "#444" }} />
        ) : undefined}
      </Box>
      {label}
    </Button>
  );
};
