import React, { useEffect, useState } from "react";

export type SetStateArg<T> = T | ((v: T) => T)
export type SetState<T> = (newState: SetStateArg<T>) => void

export const useStateWithRef = <T = any>(initialState: T): [T, SetState<T>, React.MutableRefObject<T>] => {
    const [state, _setState] = React.useState<T>(initialState);
    const ref = React.useRef<T>(state);
    const setState = React.useCallback(
        (newState: SetStateArg<T>) => {
            if (typeof newState === 'function') {
                _setState(prevState => {
                    const computedState = (newState as CallableFunction)(prevState);
                    ref.current = computedState;
                    return computedState;
                });
            } else {
                ref.current = newState;
                _setState(newState);
            }
        },
        []
    );
    return [state, setState, ref];
}

export const useTimeout = (ms?: number, reset?: string | number) => {
    const [val, set] = useState<boolean>();

    useEffect(() => {
        set(false);
        setTimeout(() => set(true), ms)
    }, [ms, reset])

    return val;
}

export const useLog = (label: string, val: any) => {
    useEffect(() => console.log(label, val), [label, val])
}

export const useNonce = (): [number, () => void] => {
    const [nonce, setNonce] = useState(Date.now());
    const refresh = () => setNonce(Date.now());

    return [nonce, refresh]
}