import { MouseEventHandler, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import { SimpleButtonProps } from "./types";
import { ModalContext } from "./modal.provider";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

export const SimpleButton = (props: SimpleButtonProps) => {
  const { label, icon, variant, state } = props;
  const navigate = useNavigate();
  const { setModal, closeModal } = useContext(ModalContext);

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation();

    if ("func" in props) {
      const { func, confirm } = props;
      if (!confirm) {
        func(state);
      } else {
        const text = typeof confirm === "string" ? confirm : label + "?";
        const handleConfirm = () => {
          func(state);
        };
        setModal({
          title: "Confirm",
          text: text,
          onConfim: handleConfirm,
        });
      }
    }
    if ("modal" in props) {
      const { modal } = props;
      const render = modal(closeModal, state);
      setModal(render);
    }
    if ("href" in props) {
      let { href } = props;

      if (href.startsWith("http")) {
        (window as any).open(href, "_blank").focus();
      } else {
        navigate(href, { state });
      }
    }
    if ("resource" in props) {
      console.log("TODO resource actions", props);
    }
  };
  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={icon}
        variant={variant}
        sx={props.sx}
        color={props.color}
      >
        {label}
      </Button>
    </>
  );
};
