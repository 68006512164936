
export enum TICKET_REASON {
    UNRECOGNISED_VOTE = 'UNRECOGNISED_VOTE',
    CHANGE_VOTE = "CHANGE_VOTE",
    WRONG_PERSON = "WRONG_PERSON",
    INACCURTATE_INFORMATION = "INACCURTATE_INFORMATION",
    DID_NOT_VOTE = "DID_NOT_VOTE",
    GENERAL_FEEDBACK = "GENERAL_FEEDBACK"
}

export interface ITicket extends IUnsavedTicket {
    id: string;
    created_at: Date;
    election_id: string;
    voter_id: string;
}

export interface IUnsavedTicket {
    election_id?: string;
    voter_id?: string;
    ballot_id?: string;
    reason: TICKET_REASON;
    voter_summary: string;
    voter_description: string;
}


export interface ITicketMessage extends IUnsavedTicketMessage {
    id: string;
    created_at: Date;
}

export interface IUnsavedTicketMessage {
    ticket_id: string;
    admin_user_id?: string;
    content?: string;
    file_upload_id?: string;
}