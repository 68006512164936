import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { BallotContext } from "../ballot.context";
import { Loading } from "../../../../components/loading";
import { RESPONSE_TYPE } from "../../../../const/vote";
import { ICandidate } from "../../../../domain/candidate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApiImage } from "../../../../components/api-image";
export const PreferenceTextInterface = () => {
  const { ballotResponse, candidates, setCandidateRank } =
    useContext(BallotContext);

  const vote =
    ballotResponse && ballotResponse.type === RESPONSE_TYPE.PREFERENCE
      ? ballotResponse.vote
      : null;

  const errors: { [id: string]: boolean } = useMemo(() => {
    if (!vote) {
      return {};
    }
    const e: any = {};
    const vals = Object.values(vote);
    for (const val of vals) {
      if (val !== null && val.index !== null) {
        if (candidates && val.index > candidates.length) {
          e[val.id] = true;
        }
        if (val.index <= 0) {
          e[val.id] = true;
        }
        if (vals.filter((v) => v.index === val.index).length > 1) {
          e[val.id] = true;
        }
      }
    }
    return e;
  }, [vote, candidates]);

  // const handleClick = (id: string) => {
  //   const rank = mapping[id];
  //   if (rank === undefined) {
  //     setRanking((_ranking) => [..._ranking, id]);
  //   } else {
  //     setRanking((_ranking) => _ranking.filter((r) => r !== id));
  //   }
  // };

  const handleInput = useCallback(
    (c: ICandidate, rank: number | null) => {
      setCandidateRank(c.id, rank, c.label);
    },
    [setCandidateRank]
  );

  console.log("vote", vote);
  const getRank = useCallback(
    (id: string) => (vote && vote[id] ? vote[id].index : null),
    [vote]
  );

  const ranks = useMemo(() => {
    if (!candidates) return [];

    return candidates.map((c) => getRank(c.id));
  }, [candidates, getRank]);

  console.log("render");
  useEffect(() => {
    console.log("ballotResponse updated");
  }, [ballotResponse]);
  useEffect(() => {
    console.log("vote updated");
  }, [vote]);
  useEffect(() => {
    console.log("getRank updated");
  }, [getRank]);
  useEffect(() => {
    console.log("ranks updated", ranks);
  }, [ranks]);

  const getNextNumber = useCallback(
    (start: number = 1) => {
      if (!candidates) {
        return 0;
      }
      const max = candidates.length;
      if (start === max) {
        return null;
      }
      for (let i = start; i <= max; i++) {
        if (!ranks.includes(i)) {
          return i;
        }
      }
      return max;
    },
    [candidates, getRank, vote, ranks]
  );

  const handleClick = useCallback(
    (c: ICandidate) => {
      const current = getRank(c.id);

      setCandidateRank(
        c.id,
        getNextNumber(current !== null ? current : 1),
        c.label
      );
    },
    [getRank, getNextNumber, vote]
  );

  useEffect(() => {
    console.log("handleClick updated");
  }, [handleClick]);

  if (!candidates) {
    return (
      <Loading source="PreferenceTextInterface" waitingFor={{ candidates }} />
    );
  }

  return (
    <Box>
      <List>
        {candidates.map((c, i) => (
          <CandidateRow
            key={c.id}
            candidate={c}
            rank={getRank(c.id)}
            error={errors[c.id]}
            onChange={handleInput}
            onClick={handleClick}
          />
        ))}
      </List>
    </Box>
  );
};
interface CandidateRowProps {
  candidate: ICandidate;
  rank: number | null;
  error: boolean;
  onChange: (c: ICandidate, rank: number | null) => void;
  onClick: (c: ICandidate) => void;
}
const CandidateRow = ({
  candidate,
  rank,
  error,
  onChange,
  onClick,
}: CandidateRowProps) => {
  const [open, setOpen] = useState(false);
  const handleInput = useCallback(
    (e: any) => {
      const raw = e.target.value;
      if (raw === "" || raw === null) {
        return onChange(candidate, null);
      }
      let value = Number(raw);
      if (value <= 0) {
        value = 1;
      }

      onChange(candidate, value);
    },
    [candidate, onChange]
  );
  const handleClick = useCallback(() => {
    onClick(candidate);
  }, [candidate, onClick]);
  const handleExpand = useCallback(() => setOpen((o) => !o), [setOpen]);
  return (
    <>
      <ListItem
        sx={{
          cursor: "pointer",
          padding: "1px 10px",
          ":hover": {
            background: "#EEE",
          },
        }}
        secondaryAction={
          candidate.statement || candidate.image_lg ? (
            <Button
              endIcon={
                <ExpandMoreIcon
                  sx={{
                    transition: "transform ease 0.5s",
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              }
              onClick={handleExpand}
            >
              Statement
            </Button>
          ) : null
        }
      >
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            {/* <NumberInput index={i} value={mapping[c.id]} /> */}
            <TextField
              sx={{
                width: 30,
                justifyContent: "center",
                justifyItems: "center",
                alignContent: "center",
                alignItems: "center",
                marginRight: 2,
                cursor: "pointer",
              }}
              value={rank || ""}
              onChange={handleInput}
              error={error}
              size="small"
              inputProps={{
                style: {
                  justifyContent: "center",
                  justifyItems: "center",
                  alignContent: "center",
                  alignItems: "center",
                  display: "flex",
                  cursor: "pointer",
                  textAlign: "center",
                  padding: 0,
                },
              }}
              onFocus={(event) => {
                event.target.select();
              }}
            />
          </ListItemIcon>
          <ListItemText primary={candidate.label} />
        </ListItemButton>
      </ListItem>
      {open ? (
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ pl: 4 }}>
          <Grid container>
            <Grid item xs={6} sm={4}>
              {candidate.image_lg ? (
                <ApiImage file_id={candidate.image_lg.id} />
              ) : null}
            </Grid>
            <Grid item xs={6} sm={8}>
              <Typography>{candidate.statement}</Typography>
            </Grid>
          </Grid>
        </Collapse>
      ) : null}
    </>
  );
};

interface NumberInputProps {
  index: number;
  value: number | undefined;
}

const NumberInput = ({ value }: NumberInputProps) => {
  return (
    <Typography
      sx={{
        border: "#AAA solid 1px",
        borderRadius: 20,
        width: 30,
        height: 30,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ":hover": {
          background: "#6ffaff",
        },
      }}
    >
      {value}
    </Typography>
  );
};
