import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "./loading";

interface RedirectProps {
  to: string;
}
export const Redirect = ({ to }: RedirectProps) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [to]);
  return <Loading source="Redirect" />;
};
