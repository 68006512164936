import { Box, Button, Grid } from "@mui/material";
import React, { useMemo } from "react";
import { LinkBtn, LinkBtnProps } from "./lnk-btn";
import { HeavyCard, HeavyCardProps } from "./heavy-card";

export interface ButtonPanelProps extends HeavyCardProps {
  next: LinkBtnProps;
  back?: LinkBtnProps;
}
export const ButtonPanel = ({ next, back, ...heavyCard }: ButtonPanelProps) => {
  const _back = useMemo((): LinkBtnProps | undefined => {
    if (!back) {
      return undefined;
    }
    return {
      label: "Back",
      variant: "outlined",
      icon: "back",
      ...back,
    };
  }, [back]);
  const _next = useMemo((): LinkBtnProps | undefined => {
    if (!next) {
      return undefined;
    }
    return {
      label: "Next",
      variant: "contained",
      icon: "next",
      fullWidth: true,
      ...next,
    };
  }, [next]);
  return (
    <HeavyCard {...heavyCard}>
      <Box display="flex" justifyContent="space-between">
        <Box>{_back ? <LinkBtn {..._back} /> : null}</Box>

        <Box marginLeft={4} display="flex" width="100%">
          <LinkBtn {..._next} />
        </Box>
      </Box>
    </HeavyCard>
  );
};
