import { SortResult } from "../components/rank-sort/types";
import { RESPONSE_TYPE } from "../const/vote";
export enum BALLOT_FORMAT {
    DRAG_DROP = "DRAG_DROP",
    NUMBER_BOX = "NUMBER_BOX"
}

export enum REFEREDUM_VALUE {
    YES = "YES",
    NO = "NO",
}

export interface IBallot {
    id: string;
    created_at: Date;
    election_id: string;
    voter_filter_id?: string;
    label: string;
    response_type: RESPONSE_TYPE;
    short_description?: string;
    shuffle_candidates: boolean;
    available_seats?: number;
    exclusive_set?: string;
    exclusive_priority?: number;
    silo_by_tag?: string;
    display_order?: number;
    min_ranking?: number;
    max_ranking?: number;
    entry_format?: BALLOT_FORMAT;
    interface_preferences?: BallotInterfacePreference;
    require_opt_in?: BallotOptInPreference;
}

export type ElectionResponse = { [ballot_page_id: string]: IBallotPageResponse };

export interface IBallotPageResponse {
    optIn?: BallotOptInResponse;
    ballot_page_id: string;
    votes: { [ballot_id: string]: IBallotResponse }
}

export type PreferenceVote = { [candidate_id: string]: SortResult<string> };
export type Vote = {
    type: RESPONSE_TYPE.PREFERENCE;
    vote: PreferenceVote;
} | {
    type: RESPONSE_TYPE.YES_NO;
    vote: REFEREDUM_VALUE;
} | {
    type: RESPONSE_TYPE.FIRST_PAST;
    vote: {
        id: string,
        label: string
    }
}

export type IBallotResponse = {
    ballot_id: string;
    validation: VoteValidation;
} & Vote



export interface BallotInterfacePreference {
    format: BALLOT_FORMAT;
    show_min: boolean;
    show_max: boolean;
}

export type BallotOptInDetails = { [key: string]: string | boolean }

export interface BallotOptInPreference {
    format_version: number;
    title: string;
    description: string;
    agreement_text?: string;
    declaration_label?: string;
}


export interface BallotOptInResponse {
    opt_in: boolean;
    opt_in_details?: BallotOptInDetails
}




export interface VoteFieldValidation {
    reason: string;
    meta?: { expected: any; found: any };
}
export interface VoteValidation {
    valid: boolean;
    reasons?: Array<VoteFieldValidation>;
}


export const validateVote = (
    ballot: IBallot,
    response: Vote
): VoteValidation => {
    if (!response) {
        return { valid: false, reasons: [{ reason: "no_response" }] };
    }
    const { vote, type } = response;

    if (vote) {
        switch (type) {
            case RESPONSE_TYPE.PREFERENCE:
                return validatePreference(ballot, vote);
        }
    }

    return { valid: false, reasons: [{ reason: "unknown" }] };
};

export const validatePreference = (ballot: IBallot, vote: PreferenceVote): VoteValidation => {
    const { max_ranking, min_ranking } = ballot;
    const reasons: Array<VoteFieldValidation> = [];
    const totalVotes = Object.values(vote).length;
    const candidates = totalVotes + 1;
    let min: number | null = null;
    let max: number | null = null;
    for (const v of Object.values(vote)) {
        if (v.index !== null && (min === null || v.index < min)) {
            min = v.index;
        }
        if (v.index !== null && (max === null || v.index > max)) {
            max = v.index;
        }
    }
    if (max_ranking && max !== null && max > max_ranking) {
        reasons.push({
            reason: "too_many_candidates",
            meta: { expected: max_ranking, found: candidates },
        });
    }
    if (min_ranking && (min === null || min < min_ranking)) {
        reasons.push({ reason: "not_enough_candidates", meta: { expected: min_ranking, found: candidates } });
    }
    return {
        valid: reasons.length === 0,
        reasons,
    };
};
