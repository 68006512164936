import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const actions: Array<DisputeActionProps> = [
  {
    title: "I would like to review or change my vote.",
    description: `Unfortunately it is not possible to review or edit submissions after you cast
        your vote. This is due to strict security and anonimity
        requirements around the voting process. No staff or
        representatives can access your vote on your behalf.`,
  },
  {
    title: "I shared my invite email with someone.",
    description: `If you shared your invite email to someone, they may have used your link to cast their vote. We can help to fix this.
    You will need to submit the name or email of the person(s) you
    shared your invite with. We will then reach out to them to
    confirm.`,
    action: "/create-ticket?reason=WRONG_PERSON",
  },
  {
    title: "I don't know what happened, but I did not submit.",
    description: `We will investigate this immediately. Please provide any details that may help the investigation.`,
    action: "/create-ticket?reason=DID_NOT_VOTE",
  },
];

export const DisputePage = () => {
  const { election_id } = useParams();
  return (
    <Grid container>
      <Grid item xs={12} mb={3}>
        <Typography variant="h4">Dispute Election Response</Typography>
        <Link to={`/election/${election_id}`}>← Back</Link>
      </Grid>
      <Grid item xs={12}>
        <Typography mb={1}>Please choose an option</Typography>
        {actions.map((a, i) => (
          <DisputeAction key={i} {...a} />
        ))}
      </Grid>
    </Grid>
  );
};

interface DisputeActionProps {
  title: string;
  description: string;
  action?: string;
}
const DisputeAction = ({ title, description, action }: DisputeActionProps) => {
  return (
    <Card
      raised
      sx={{ marginBottom: 3, background: action ? "#dfedec" : "#f3eee5" }}
    >
      <Box p={3}>
        <Typography variant="h5">{title}</Typography>
        <Typography>{description}</Typography>
        {action ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to={action} style={{ textDecoration: "none", color: "#000" }}>
              <Button>Click here to begin</Button>
            </Link>
          </Box>
        ) : null}
      </Box>
    </Card>
  );
};
