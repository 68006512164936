import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  BallotOptInDetails,
  BallotOptInPreference,
  IBallot,
} from "../../../domain/ballot";
import { useContext, useMemo, useState } from "react";
import { ElectionContext } from "../election.context";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../components/loading";
import { ErrorPanel } from "../../../components/error-panel";
import { HeavyCard } from "../../../components/heavy-card";
import { BallotPageContext } from "./ballot-page.context";
import { IBallotOptIn } from "../../../domain/ballot-opt-in";

export const BallotOptInPanel = () => {
  const { ballotPage, setOptIn, optInResponse } = useContext(BallotPageContext);
  const { opt_in } = ballotPage;

  const { title, description, agreement_text } = opt_in || {};
  const [agree, setAgree] = useState<boolean>(!agreement_text);

  const valid = useMemo(() => {
    if (agreement_text && !agree) {
      return false;
    }
    return true;
  }, [agreement_text, agree]);

  const handleAgree = () => {
    setAgree(!agree);
  };

  const handleOptIn = () => {
    const details: BallotOptInDetails = {};
    if (agreement_text) {
      details[agreement_text] = agree ? "Agree" : "Disagree";
    }
    setOptIn({
      opt_in: true,
      opt_in_details: details,
    });
  };
  const handleOptOut = () => {
    setOptIn({
      opt_in: false,
    });
  };
  if (!opt_in) {
    return null;
  }
  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <HeavyCard title="Opt In">
          <Typography variant="h5">{title}</Typography>

          <Typography>{description}</Typography>

          {agreement_text ? (
            <Grid item xs={12} mb={2}>
              <FormControlLabel
                control={<Checkbox checked={agree} onChange={handleAgree} />}
                label={agreement_text}
              />
            </Grid>
          ) : null}
        </HeavyCard>
      </Grid>

      <Grid item xs={12} mt={3}>
        <Button
          color="primary"
          variant="contained"
          disabled={!valid}
          onClick={handleOptIn}
        >
          Opt-In
        </Button>
        <Button color="primary" variant="outlined" onClick={handleOptOut}>
          Opt-Out
        </Button>
      </Grid>
    </Grid>
  );
};
