import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createContext } from "react";
import {
  ElectionResponse,
  IBallot,
  IBallotPageResponse,
  IBallotResponse,
} from "../../domain/ballot";
import { AuthContext } from "../../context/auth.provider";
import { useLocation } from "react-router-dom";
import {
  ApiBallotPage,
  ApiElectionRound,
  ApiIBallot,
  VOTING_ROUND_STATUS,
} from "../../domain/election";
import { useStoredJson } from "../../context/localstorage.provider";
import { ApiContext, useFetchState } from "../../context/api.provider";
import { AxiosResponse } from "axios";
import BallotIcon from "@mui/icons-material/Ballot";
import GradingIcon from "@mui/icons-material/Grading";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";

import { CONSUMER_TYPE } from "../../domain/consumer";
import { VOTER_STATUS } from "../../domain/voter";
import { IBallotPage } from "../../domain/ballot-page";
import { Loading } from "../../components/loading";
import { useNonce } from "../../hooks";
export enum INTERFACE_STATE {
  LOADING = "LOADING",
  VOTING_ALLOWED = "VOTING_ALLOWED",
  ALREADY_SUBMITTED = "ALREADY_SUBMITTED",
  ELECTION_NOT_YET_OPEN = "ELECTION_NOT_YET_OPEN",
  ELECTION_ALREADY_CLOSED = "ELECTION_ALREADY_CLOSED",
}

export interface SubmitResult {
  success: boolean;
  message?: string;
  code: string;
}

export interface IElectionContext {
  pageResponses: ElectionResponse;
  election: ApiElectionRound;
  ballotPages: Array<ApiBallotPage>;
  setBallotPageResponse: (
    ballotPageId: string,
    value: React.SetStateAction<IBallotPageResponse>
  ) => void;
  submit: () => Promise<AxiosResponse>;
  interfaceState: INTERFACE_STATE;
  refreshElectionState: () => void;
}

export const ElectionContext = createContext<IElectionContext>({
  pageResponses: {},
  election: {} as any,
  ballotPages: [],
  setBallotPageResponse: (ballotPageId: string) => null,
  submit: () => {
    throw new Error("not initialized");
  },
  interfaceState: INTERFACE_STATE.LOADING,
  refreshElectionState: () => null,
});
export interface IElectionStep {
  id: string;
  label: string;
  url: string;
  completed: boolean | null;
  icon?: JSX.Element;
}
interface ElectionProviderProps extends PropsWithChildren {
  election_id: string;
  consumer_id: string;
}
export const ElectionProvider = ({
  children,
  election_id,
  consumer_id,
}: ElectionProviderProps) => {
  const [nonce, refresh] = useNonce();
  const [electionResponse, fetchElection] = useFetchState<ApiElectionRound>();
  const election = electionResponse ? electionResponse.data : null;
  useEffect(() => {
    fetchElection({
      url: `/elections/${election_id}`,
    });
  }, [election_id, nonce]);

  const { consumer } = useContext(AuthContext);
  const { fetch } = useContext(ApiContext);
  const [] = useState();
  const ballotPages = useMemo(
    () => (election ? Object.values(election.ballot_pages) : []),
    [election]
  );

  const [pageResponses, setResponseSet] = useStoredJson<ElectionResponse>(
    `response_${election_id}_${consumer_id}`,
    {}
  );

  const setBallotPageResponse = useCallback(
    (
      ballotPageId: string,
      value: React.SetStateAction<IBallotPageResponse>
    ) => {
      setResponseSet((responses) => {
        if (!responses) throw new Error("PageResponses not initialized");
        const current: IBallotPageResponse = responses[ballotPageId] || {
          ballot_page_id: ballotPageId,
          votes: {},
        };
        const newValue = typeof value === "function" ? value(current) : value;
        return {
          ...responses,
          [ballotPageId]: newValue,
        };
      });
    },
    []
  );
  const interfaceState = useMemo(() => {
    if (!consumer || !election) {
      // not ready
      return INTERFACE_STATE.LOADING;
    }
    if (
      consumer.type === CONSUMER_TYPE.VOTER &&
      consumer.voter.status === VOTER_STATUS.SUBMITTED
    ) {
      return INTERFACE_STATE.ALREADY_SUBMITTED;
    }
    if (election.status === VOTING_ROUND_STATUS.VOTING) {
      // all users can access ballots when it's running.
      return INTERFACE_STATE.VOTING_ALLOWED;
    }

    if (
      [VOTING_ROUND_STATUS.PENDING, VOTING_ROUND_STATUS.NOMINATIONS].includes(
        election.status
      )
    ) {
      if (consumer.type === "ADMIN_USER") {
        return INTERFACE_STATE.VOTING_ALLOWED;
      }
      return INTERFACE_STATE.ELECTION_NOT_YET_OPEN;
    }

    return INTERFACE_STATE.ELECTION_ALREADY_CLOSED;
  }, [consumer, election]);

  const handleSubmit = async (): Promise<AxiosResponse> => {
    if (!fetch) {
      throw Error("fetch is required for submission");
    }

    try {
      return await fetch({
        url: `/elections/${election_id}/response`,
        method: "post",
        data: pageResponses,
      });
    } catch (err) {
      return err as any;
    }
  };
  if (!election) {
    return <Loading source="ElectionProvider" waitingFor={{ election }} />;
  }
  if (!ballotPages || !pageResponses) {
    return <Loading source="ElectionProvider" />;
  }
  return (
    <ElectionContext.Provider
      value={{
        election,
        ballotPages: ballotPages,
        pageResponses,
        interfaceState,
        setBallotPageResponse: setBallotPageResponse,
        submit: handleSubmit,
        refreshElectionState: refresh,
      }}
    >
      {children}
    </ElectionContext.Provider>
  );
};
