import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Loading } from "../../../components/loading";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";
import { BallotPageContext, BallotPageProvider } from "./ballot-page.context";
import { ElectionContext } from "../election.context";
import { BallotOptInPanel } from "./ballot-opt-in-page";
import { BallotContext, BallotProvider } from "../ballot/ballot.context";
import { BallotVotePage } from "../ballot/ballot-vote-page";
import { ButtonPanel } from "../../../components/button-panel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const BallotPageLayout = () => {
  const { ballot_page_id } = useParams();

  const { ballotPages } = useContext(ElectionContext);

  const ballotPage = useMemo(
    () => ballotPages.find((bp) => bp.id === ballot_page_id),
    [ballotPages, ballot_page_id]
  );

  if (!ballotPage) {
    return <Loading source="BallotPanel" />;
  }

  return (
    <BallotPageProvider ballotPage={ballotPage}>
      <Outlet />
    </BallotPageProvider>
  );
};
