import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { Loading } from "../../../components/loading";
import { useNavigate, useOutlet, useParams } from "react-router-dom";
import { BallotPageContext, BallotPageProvider } from "./ballot-page.context";
import { ElectionContext } from "../election.context";
import { BallotOptInPanel } from "./ballot-opt-in-page";
import { BallotContext, BallotProvider } from "../ballot/ballot.context";
import { BallotVotePage } from "../ballot/ballot-vote-page";
import { ButtonPanel } from "../../../components/button-panel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const BallotPageVote = () => {
  const { ballotPage, ballots, optInResponse } = useContext(BallotPageContext);
  const [openOptIn, setOpenOptIn] = useState(!optInResponse);
  const showBallot = optInResponse || !ballotPage.opt_in;
  useEffect(() => {
    if (optInResponse) setOpenOptIn(false);
  }, [optInResponse]);

  return (
    <>
      {ballotPage.opt_in ? (
        <Accordion expanded={openOptIn || !optInResponse}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setOpenOptIn(!openOptIn)}
          >
            <Typography>{ballotPage.opt_in.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BallotOptInPanel />
          </AccordionDetails>
        </Accordion>
      ) : null}

      {showBallot ? (
        <Grid container>
          {ballots.map((b) => (
            <Grid item xs={12} key={b.id} mb={2}>
              <BallotProvider ballot={b}>
                <BallotVotePage />
              </BallotProvider>
            </Grid>
          ))}
          <Grid item xs={12}>
            <ButtonPanel
              back={{ to: `/election/${ballotPage.election_id}` }}
              next={{
                to: `/election/${ballotPage.election_id}/ballot_page/${ballotPage.id}/review`,
                label: "Review",
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};
