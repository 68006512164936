export interface IAuth {
    token_id: string;
    token_secret: string;
}

export enum VOTER_STATUS {
    INACTIVE = "INACTIVE",
    VIEWED = "VIEWED",
    PARTIAL_SUBMIT = "PARTIAL_SUBMIT",
    SUBMITTED = "SUBMITTED"
}


export interface IVoter {
    id: string;
    created_at: Date | string;
    election_id: string;
    voter_digest_id?: string;
    title?: string;
    first_name: string;
    last_name: string;
    preferred_name?: string;
    email: string;
    status: VOTER_STATUS;
}