import { Box, Button, Grid, Typography } from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";

import { RESPONSE_TYPE } from "../../../const/vote";
import { IBallot } from "../../../domain/ballot";
import { PreferenceInput } from "./preference";
import { ResponseYesNo } from "./referendum";
import { HeavyCard } from "../../../components/heavy-card";
import { BallotContext } from "./ballot.context";
import { ResponseFirstPast } from "./first-past";

export const BallotVotePage = () => {
  const { ballot } = useContext(BallotContext);

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <HeavyCard>
          <Typography variant="h6">{ballot.label}</Typography>
          <hr />
          {ballot.short_description ? (
            <>
              <Typography variant="body1">
                {ballot.short_description}
              </Typography>
              <hr />
            </>
          ) : null}
          <ResponseResponse ballot={ballot} />
        </HeavyCard>
      </Grid>
    </Grid>
  );
};
interface ResponseResponseProps {
  ballot: IBallot;
}
const ResponseResponse = ({ ballot }: ResponseResponseProps) => {
  switch (ballot.response_type) {
    case RESPONSE_TYPE.YES_NO:
      return <ResponseYesNo />;
    case RESPONSE_TYPE.FIRST_PAST:
      return <ResponseFirstPast />;
    default:
      return <PreferenceInput />;
  }
};
