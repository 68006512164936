import { useContext, useEffect, useMemo, useState } from "react";
import { ICandidateProfile } from "../../domain/candidate";
import { SortResult } from "../../components/rank-sort/types";
import { ApiContext } from "../../context/api.provider";

export const useCandidates = (ballot_id: string) => {
    const { fetch } = useContext(ApiContext);
    const [candidates, setCandidates] = useState<ICandidateProfile[] | null>(null);

    useEffect(() => {
        if (fetch) {
            fetch({ method: 'get', url: `/ballots/${ballot_id}/candidates` }).then(r => setCandidates(r.data));
        }
    }, [fetch, ballot_id])

    return candidates
}


export const sortResult = (a: SortResult, b: SortResult) => {
    if (a.index === null)
        return 1;
    if (b.index === null)
        return -1;
    return a.index > b.index ? 1 : -1
}

export const formateDate = (date: Date | string) => {
    date = new Date(date);
    return date.toDateString();
}