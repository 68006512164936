import { Box, Card, SxProps, Theme, Typography } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { Colors } from "../domain/theme";

const COLOR_MAP: { [color in Colors]?: string } = {
  default: "#AAA",
  success: "rgb(129, 199, 132)",
  primary: "rgb(79, 195, 247)",
  warning: "rgb(255, 183, 77)",
  error: "rgb(229, 115, 115)",
};
export interface HeavyCardProps extends PropsWithChildren {
  title?: string;
  color?: Colors;
  sx?: SxProps<Theme>;
}
export const HeavyCard = ({ title, children, color, sx }: HeavyCardProps) => {
  const borderColor = useMemo(
    () => (color && COLOR_MAP[color] ? COLOR_MAP[color] : COLOR_MAP["default"]),
    [color]
  );
  return (
    <Card
      sx={{
        border: "solid 1px",
        borderColor: borderColor,
        boxShadow: `0px 2px 1px -1px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`,
        ...sx,
      }}
    >
      <Box p={3}>
        {title ? (
          <>
            <Typography variant="h6">{title}</Typography>
            <hr />
          </>
        ) : null}

        {children}
      </Box>
    </Card>
  );
};
