import { Button } from "@mui/material";
import React, { FC, PropsWithChildren, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

type Icons = "back" | "next" | "submit";
export interface LinkBtnProps extends PropsWithChildren {
  label?: string;
  to?: string;
  variant?: "text" | "outlined" | "contained";
  fullWidth?: boolean;
  icon?: JSX.Element | Icons;
  onClick?: () => void;
  disabled?: boolean;
}
export const LinkBtn = ({
  label,
  to,
  icon,
  variant,
  fullWidth,
  children,
  disabled,
  onClick,
}: LinkBtnProps) => {
  const _startIcon = useMemo(() => {
    if (typeof icon === "string") {
      switch (icon) {
        case "back":
          return <ArrowBackIcon />;
        case "submit":
          return <CheckIcon />;
        default:
          return undefined;
      }
    }
    return icon;
  }, [icon]);
  const _endIcon = useMemo(() => {
    if (typeof icon === "string") {
      if (typeof icon === "string") {
        switch (icon) {
          case "next":
            return <ArrowForwardIcon />;

          default:
            return undefined;
        }
      }
      return icon;
    }
  }, [icon]);
  const button = useMemo(() => {
    return (
      <Button
        startIcon={_startIcon}
        endIcon={_endIcon}
        fullWidth={fullWidth}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
        {label}
      </Button>
    );
  }, [
    _startIcon,
    _endIcon,
    fullWidth,
    variant,
    onClick,
    children,
    label,
    disabled,
  ]);
  if (to) {
    return (
      <Link style={{ width: fullWidth ? "100%" : undefined }} to={to}>
        {button}
      </Link>
    );
  }
  return button;
};
