import React, {
  createContext,
  useEffect,
  useState,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { IVoter } from "../domain/voter";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwt from "jwt-encode";
import { API_BASE } from "../const/config";
import { LocalStorageContext } from "./localstorage.provider";
import {
  ILoginToken,
  IActionTokenResponse,
  IConsumerApiToken,
} from "../domain/email-token";
import { staticAuthFetch } from "../util/api";
import { IConsumer } from "../domain/consumer";
export enum AUTH_STATUS {
  VERIFYING = "VERIFYING",
  AUTHENTICATED = "AUTHENTICATED",
  UNAUTHENTICATED = "UNAUTHENTICATED",
}
export interface IAuthContext {
  authByLoginToken: (code: string) => Promise<IConsumerApiToken>;
  consumer: IConsumer | null;
  status: AUTH_STATUS;
}

export interface HttpRequest {}

export const AuthContext = createContext<IAuthContext>({
  authByLoginToken: (code: string) => {
    throw new Error("Not initialized");
  },
  consumer: null,
  status: AUTH_STATUS.VERIFYING,
});
export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const { getString, setString, delValue } = useContext(LocalStorageContext);
  const publicKey = getString("public_key");
  const secretKey = getString("secret_key");
  const [status, setStatus] = useState<AUTH_STATUS>(AUTH_STATUS.VERIFYING);

  const [consumer, setConsumer] = useState<IConsumer | null>(null);

  const deAuth = () => {
    setStatus(AUTH_STATUS.UNAUTHENTICATED);
    delValue("public_key");
    delValue("secret_key");
  };

  const authByLoginToken = (token: string) => {
    console.log("INITIALIZED");
    return axios<IActionTokenResponse>({
      url: API_BASE + "/action-token",
      method: "post",
      data: { token },
    }).then(({ data }) => {
      console.log({ data });
      const { payload } = data;
      setString("public_key", payload.public_key);
      setString("secret_key", payload.secret_key);
      payload.public_key = "";
      payload.secret_key = "";
      return payload;
    });
  };

  useEffect(() => {
    if (publicKey && secretKey) {
      staticAuthFetch(
        {
          method: "get",
          url: "/status",
        },
        publicKey,
        secretKey
      )
        .then((response) => {
          setConsumer(response.data);
          setStatus(AUTH_STATUS.AUTHENTICATED);
        })
        .catch(() => deAuth());
    } else {
      setStatus(AUTH_STATUS.UNAUTHENTICATED);
    }
  }, [fetch, publicKey, secretKey]);

  return (
    <AuthContext.Provider value={{ authByLoginToken, consumer, status }}>
      {props.children}
    </AuthContext.Provider>
  );
};
