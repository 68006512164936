import { useState, useContext } from "react";
import { BALLOT_FORMAT } from "../../../../domain/ballot";
import { ICandidateProfile } from "../../../../domain/candidate";
import { Box, Typography } from "@mui/material";
import { PreferenceTextInterface } from "./text-interface";
import { BallotContext } from "../ballot.context";

export const PreferenceInput = () => {
  const { minRank, maxRank } = useContext(BallotContext);
  const [_interface, setInterface] = useState<BALLOT_FORMAT>(
    BALLOT_FORMAT.DRAG_DROP
  );

  return (
    <Box>
      <Box>
        {minRank ? (
          <Typography>
            You must choose at least{" "}
            <b>
              <u>{minRank}</u>
            </b>{" "}
            candidates.
          </Typography>
        ) : null}
        {maxRank ? (
          <Typography>
            You can choose at most{" "}
            <b>
              <u>{maxRank}</u>
            </b>{" "}
            candidates.
          </Typography>
        ) : null}

        <PreferenceTextInterface />
      </Box>
    </Box>
  );
};

interface CandidateDragProps {
  candidate: ICandidateProfile;
}
