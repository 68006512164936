import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE } from "../const/config";

interface ApiImageProps {
  file_id: string;
}
export const ApiImage = ({ file_id }: ApiImageProps) => {
  const [raw, setRaw] = useState<string>();
  useEffect(() => {
    axios({ url: API_BASE + `/assets/${file_id}`, responseType: "blob" }).then(
      (response) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(response.data);
        reader.onload = function () {
          var imageDataUrl = reader.result;
          setRaw(imageDataUrl as string);
        };
      }
    );
  }, [fetch, file_id]);

  if (!raw) {
    return null;
  }

  return (
    <img
      src={raw}
      style={{
        maxWidth: "300px",
        maxHeight: "100%",
        width: "100%",
        height: "100%",

        objectFit: "contain",
      }}
    />
  );
};
